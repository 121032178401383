exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-all-services-js": () => import("./../../../src/pages/all-services.js" /* webpackChunkName: "component---src-pages-all-services-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-landing-page-home-jsx": () => import("./../../../src/templates/landingPageHome.jsx" /* webpackChunkName: "component---src-templates-landing-page-home-jsx" */),
  "component---src-templates-landing-page-iv-jsx": () => import("./../../../src/templates/landingPageIV.jsx" /* webpackChunkName: "component---src-templates-landing-page-iv-jsx" */),
  "component---src-templates-landing-page-nad-js": () => import("./../../../src/templates/landingPageNAD.js" /* webpackChunkName: "component---src-templates-landing-page-nad-js" */),
  "component---src-templates-landing-page-specific-iv-jsx": () => import("./../../../src/templates/landingPageSpecificIV.jsx" /* webpackChunkName: "component---src-templates-landing-page-specific-iv-jsx" */),
  "component---src-templates-landing-page-weightloss-js": () => import("./../../../src/templates/landingPageWeightloss.js" /* webpackChunkName: "component---src-templates-landing-page-weightloss-js" */),
  "component---src-templates-treatments-page-js": () => import("./../../../src/templates/treatmentsPage.js" /* webpackChunkName: "component---src-templates-treatments-page-js" */)
}

